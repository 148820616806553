<template>
  <div>
    <b-table
        id="table-transition-example"
        class="vgt-table bordered mb-1"
        :items="listOfData"
        name="id"
        :fields="fields"
        responsive
        striped
        show-empty
        :tbody-transition-props="transProps"
        primary-key="id"
        :busy="loadingTable"
        @sort-changed="sortChanged"
        :no-sort-reset="true"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner
            type="grow"
            label="Loading..."
            variant="primary"
          />
        </div>
      </template>

      <template
          v-for="(field,key) in fields"
          v-slot:[`cell(${field.key})`]="data"
      >
        <div :key="key">
          <!-- handle Image -->
          <b-avatar
              v-if="field.type=='image'"
              class="avatar-50"
              :src="$_.get(data.item, field.key) ? $_.get(data.item, field.key) : require('@/assets/images/user/default-user-image.png')"
          />

          <!-- handle status -->
          <strong v-else-if="field.type == 'status'" class="py-1 iq-border-radius-5 font-size-1 text-center">
            <mainstatus :status="$_.get(data.item, field.key)" />
          </strong>

          <!-- actions -->
          <div v-else-if="field.type == 'actions'">
            <cell-actions
              :cell-actions="field"
              :data-item="data.item"
            />
          </div>
          <!-- transactions handler -->
          <div class="d-flex align-items-center" v-else-if="field.type == 'transactions'">
            <div class="d-flex align-items-center justify-content-center" v-if="$_.get(data.item, field.key) === 'deposit'">
             <span class="deposit"><i class="las la-long-arrow-alt-up"></i></span>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="$_.get(data.item, field.key) === 'withdrawal'">
              <span class="withdrawal"><i class="las la-long-arrow-alt-down"></i></span>
            </div> {{$_.get(data.item, field.key)}}
          </div>
          <!-- Array handler -->
          <div v-else-if="field.type == 'array'">
            <span v-if="field.array_keys" >
              <ul class="p-0">
                <li v-for="(arr, key) in $_.get(data.item, field.key)" :key="key">
                    <span v-for="(ind, key1) in field.array_keys" :key="key1">{{ arr[ind] }} </span>
                </li>
              </ul>
            </span>
            <span v-else>
              <span v-for="(arr, key) in $_.get(data.item, field.key)" :key="key">
                {{ arr }}
                <span v-if="(key < $_.get(data.item, field.key).length-1)">,</span>
              </span>
            </span>
          </div>

          <!-- Multi-image handler -->
          <div class="min-width-image-cell" v-else-if="field.type == 'multi_image'">
            <div class="iq-media-group position-relative">
              <b-link href="#" class="iq-media" v-for="(image, counter) in $_.get(data.item, field.key).slice(0,3)" :key="counter">
                <b-img  class="avatar-50" rounded="circle" fluid :src="image" :alt="image.name" />
                <div v-if="($_.get(data.item, field.key).length > 3) && counter === 2" class="more-images text-white">{{ $_.get(data.item, field.key).length-3 }}+</div>
              </b-link>
            </div>
          </div>

          <!-- Multi-text handler -->
          <div v-else-if="field.type == 'multi-text'">
            <span v-for="(arrKey, key) in field.key.split(',')" :key="key">
              <span v-if="$_.get(data.item, arrKey)">
                {{ $_.get(data.item, arrKey) }}
              </span>
            </span>
          </div>

          <!-- Multi-value handler -->
          <div v-else-if="field.type == 'multi-value'">
            <ul class="p-0">
              <li v-for="(arrKey, key) in field.key.split(',')" :key="key">
                <span v-if="$_.get(data.item, arrKey) > 0">
                  <span v-if="arrKey.includes('egp')">EGP </span>
                  <span v-else-if="arrKey.includes('euro')">EUR </span>
                  <span v-else-if="arrKey.includes('dollar')">$ </span>
                  {{ $_.get(data.item, arrKey) }}
                </span>
                <span v-else>N/A</span>
              </li>
            </ul>
          </div>
          <div v-else-if="field.type == 'format'">
            {{ field.format(data.item) }}
            <!-- <span v-if="$_.get(data.item, field.key) > 0">
            </span>
            <span v-else>N/A</span> -->
          </div>
          <div v-else-if="field.type == 'date'">
            {{ new Date($_.get(data.item, field.key)).toLocaleDateString() }}
            <!-- <span v-if="$_.get(data.item, field.key) > 0">
            </span>
            <span v-else>N/A</span> -->
            </div>

          <!-- handle Text -->
          <p v-else class="text-nowrap m-0">
            <span v-if="$_.get(data.item, field.key)">
              {{ $_.get(data.item, field.key).length > 30 ? $_.get(data.item, field.key).substring(0,30) + '...' : $_.get(data.item, field.key) }}
            </span>
            <span v-else-if="$_.get(data.item, field.key) == 0">0</span>
            <span v-else>---</span>
          </p>
        </div>

      </template>
      <template v-slot:cell(change_status)="data" >
        <changeStatus
            :allData = data
            v-if="data.item.status && data.item.status == 'processing'"
            :id="data.item.id"
            :type="data.field.tableType"
            :status="data.item.status"
            :statusKeyId="data.field.idKey"
            @changeStatus="changeStatus"
        />
      </template>
    </b-table>
    <div v-if="list_url && hasPagination" class="bg-primary py-2 px-3 d-flex justify-content-between align-items-center iq-border-radius-5 mt-0">
      <span>{{ pagination.page }} من {{ pagination.pageCount }} - {{ pagination.itemPerPage }} {{paginationName}}</span>
      <div class="d-flex flex-nowrap justify-content-end align-items-center gap_1 flex-grow-1">
          <span>الصفوف لكل صفحة</span>
          <b-select class="footer-select" v-model="limit" :options="[10,25,50]">
          </b-select>
          <div class="d-flex">
            <span class="bg-white iq-border-radius-5 arroy-footer-table mb-0 ml-1 cursor-pointer" v-if="pagination.hasNextPage" @click="pagination.page=pagination.page  + 1">
              <i class="las la-arrow-right text-primary"></i>
            </span>
            <span class="bg-gray iq-border-radius-5 arroy-footer-table mb-0 ml-1" v-else>
              <i class="las la-arrow-right text-primary"></i>
            </span>
            <span class="bg-white iq-border-radius-5 arroy-footer-table mb-0 cursor-pointer" v-if="pagination.hasPreviousPage"  @click="pagination.page=pagination.page - 1">
              <i class="las la-arrow-left text-primary"></i>
            </span>
            <span class="bg-gray iq-border-radius-5 arroy-footer-table mb-0 ml-1" v-else>
              <i class="las la-arrow-left text-primary"></i>
            </span>
          </div>
        </div>
    </div>
    <!--    <b-pagination
          v-if="pagination.total > pagination.per_page"
          v-model="pagination.current_page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          first-number
          last-number
          class="mb-3 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          @input="getListData"
        >
        </b-pagination>-->
  </div>
</template>

<script>
import Bus from '@/eventBus'
import mainstatus from './status'
import mainService from '@/services/main'
import cellActions from './cellActions'
import changeStatus from './changeStatus'
import { core } from '@/config/pluginInit'
export default {
  components: {
    mainstatus,
    cellActions,
    changeStatus
  },
  props: {
    fields: { type: Array, required: true },
    items: { type: [Array, Object], required: false },
    baseURL: { type: String, default: '' },
    list_url: { type: String },
    statusUrl: { type: String, default: '' },
    additionalUrl: { type: String },
    params: { type: [Object, Array] },
    reloadData: { type: Boolean, default: false },
    limitStatus: { type: Boolean, default: false },
    paginationName: { type: String, default: 'عنصر' },
    hasPagination: { type: Boolean, default: true }
  },
  data () {
    return {
      transProps: {
        name: 'flip-list'
      },
      limit: 10,
      page: 1,
      listOfData: [],
      pagination: {
        page: 1,
        take: 10,
        itemsPerPage: 0,
        total: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
      },
      loadingTable: false,
      moreImages: 2
    }
  },
  watch: {
    params: {
      handler (val) {
        this.getListData()
      },
      deep: true
    },
    items () {
      this.getListData()
    },
    limit (val) {
      console.log(val)
      console.log('limit changed')
      this.getListData()
    },
    reloadData (val) {
      if (val) {
        // this.items = null
        this.getListData()
      }
    },
    'pagination.page' (val) {
      if (val > 0) {
        // this.items = null
        this.getListData()
      }
    },
    additionalUrl () {
      this.getListData()
    }
  },
  created () {
    this.getListData()
  },
  methods: {
    async getListData () {
      this.listOfData = []
      let filters = {}
      if (this.params) {
        // this.params.map(data => {
        //   filters[data.name] = data.checked
        // })
        filters = this.params
      }
      this.loadingTable = true
      let List = []
      if (!Array.isArray(this.items) && !this.items?.length > 0) {
        List = await mainService.listDataTabl(`${this.list_url}?page=${this.pagination.page}${'&take=' + this.limit}${this.additionalUrl ? this.additionalUrl : ''}`, filters, this.baseURL)
        if (List.data) {
          // this.items = List.data.data
          this.listOfData = List.data.data
          this.pagination = {
            totalPages: List.data?.meta?.totalPages ?? 1,
            page: List.data?.meta?.page ?? 1,
            total: List.data?.meta?.total ?? 0,
            // itemsPerPage: List.data?.meta?.itemsPerPage ?? 0,
            pageCount: List.data?.meta?.pageCount ?? 0,
            hasPreviousPage: List.data?.meta?.hasPreviousPage ?? false,
            hasNextPage: List.data?.meta?.hasNextPage ?? false
          }
        }
      } else {
        List = this.data
        this.listOfData = this.items
      }
      this.loadingTable = false
      this.reloadData = false
    },
    sortChanged (data) {
      this.$emit('sortChanged', data)
    },
    changeStatus (data) {
      mainService.changeStatus(this.baseURL, this.statusUrl, data.data.item.id).then(res => {
        core.showSnackbar('success', res.data.message)
      })
    }
  },
  mounted () {
    Bus.$on('reloadTableAfterDelete', ifReload => { this.reloadData = true })
  },
  computed: {
    currentIndex () {
      return (this.pagination.page - 1) * this.pagination.itemsPerPage
    }
  }
}
</script>
<style>
table#table-transition-example .flip-list-move {
  transition: transform 0.5s;
}
.footer-select {
  width: 70px !important;
}
.arroy-footer-table{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 40px;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 20px;
}
.arroy-footer-table i {
  font-size: 20px;
}
.deposit{
  /*background-color: #0abb78;*/
  color: #0abb78;
  transform: rotate(45deg);
}
.withdrawal{
  color: #e64141;
  transform: rotate(45deg);
}
</style>
